import React from 'react';
import { breakpoints } from '@naf/theme';
import { GridCol } from '@naf/grid';
import { RelatedArticleType } from '../../../../../types/articleType';
import useSelector from '../../../redux/typedHooks';
import { useResponsiveImageSize } from '../../../hooks/useResponsiveImageSize';
import { cloudinaryImage } from '../../../utils/imageUrl';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { StyledLink } from '../../../components/styled-link/StyledLink';
import { StyledCardGrid } from '../Styles';

export const ArticleCard = ({ article }: { article: RelatedArticleType }) => {
  const application = useSelector((state) => state.application);
  const responsiveImagesSize = useResponsiveImageSize(536);
  const imagesSize: number =
    responsiveImagesSize?.width > parseInt(breakpoints.m, 10) ? 536 : responsiveImagesSize.width;

  const itemImage =
    article.image && article.image.publicId && imagesSize
      ? cloudinaryImage(article.image.publicId, application, imagesSize)
      : undefined;
  const itemUrl = useDocumentUrlWithNode(article) || '';
  const isMember = useSelector((state) => state?.myMembership?.customerInformation?.data?.membership?.isValid);

  return (
    <GridCol s="12" m="12" l="6" xl="6" key={`article-item-${article.name}`}>
      <StyledLink to={itemUrl} $hasCardGrid>
        <StyledCardGrid
          headerTag="h2"
          title={article.name}
          img={{
            src: itemImage || '',
            alt: article.image?.altText || '',
          }}
          memberLabel={
            article.membersOnly
              ? {
                  labelText: 'For medlemmer',
                  isEligible: isMember,
                }
              : undefined
          }
        >
          {article.ingress}
        </StyledCardGrid>
      </StyledLink>
    </GridCol>
  );
};
